import {
  Accordion,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  InlineNotification,
  Modal,
  Option,
  Spinner,
  TableIconButton,
  Tag,
  Text,
  TextInput,
  Toggle,
  useTranslation,
} from "@familyzone/component-library";
import React, { useState } from "react";
import { UserSearchSelector } from "../../UserSearch/UserSearchSelector";
import { AllOptions, Keyword, Signature, Verdict } from "../../../pages/filtering/types";
import { deletePolicy, getPolicies, getVerdict, modifyPolicy, validateDomain } from "../../../pages/filtering/ApiHelpers";
import { FilteringCriteria, KeywordObject, WebsiteObject } from "../../../modules/criteria/criteriaTypes";
import { convertCriteria } from "../../../pages/filtering/SignatureSelector";
import { DeleteButtonNew } from "../../../modules/DeleteButtonNew";
import SignatureHierarchy from "../SignatureHierarchy";
import TestPolicySafetyNetMatch from "./TestPolicySafetyNetMatch";
import PolicyCriteria from "./PolicyCriteria";
import StatusChip, { ALLOWED_TEXT } from "../StatusChip";
import TestPolicyCommunityPauseInternet from "./TestPolicyCommunityPauseInternet";

export const headerText = "Test Policy";
export const headerDescText = "Check whether a website is 'blocked' or 'allowed' for a specific user based on your active policy set.";
export const checkButtonText = "Check";
export const clearButtonText = "Clear";
export const websiteLabelText = "Website";
export const websitePlaceholderText = "e.g. youtube.com";
export const userPlaceholderText = "Select User";
export const userText = "User";
export const policyText = "Existing School Policy";
export const policyCriteriaText = "Policy Criteria";
export const criteriaHierarchyText = "Criteria Hierarchy";
export const editPolicyText = "Edit Policy";
export const classroomRuleText = "Classroom Rule";
export const classroomPolicyText = "Existing Classroom Policy";
export const noPoliciesText = "No policies are currently blocking this request";
export const errorTitle = "Error checking website access";
export const verdictErrorDescText = "Encountered an error while checking the access";

type SignatureSummary = {
  id: string;
  name: string;
  icon: string;
  included: boolean;
  important: boolean;
  action: number;
  category: string;
};

type SignatureTree = Omit<SignatureSummary, "category"> & {
  children: SignatureTree[];
};

export interface TestPolicyModalProps {
  open: boolean;
  signatures: Signature[];
  keywords: Keyword[];
  websiteObjects: WebsiteObject[];
  keywordObjects: KeywordObject[];
  onRefresh: () => void;
  onClose: () => void;
  editRule: (ruleId: string) => void;
}

const TestPolicyModal: React.FC<TestPolicyModalProps> = ({
  open = false,
  signatures,
  keywords,
  websiteObjects,
  keywordObjects,
  onRefresh = () => "",
  onClose = () => "",
  editRule = () => "",
}) => {
  const { t } = useTranslation();

  const [submittedURL, setSubmittedURL] = useState("");

  const [ruleDropdownOpen, setRuleDropdownOpen] = useState<boolean>(false);

  const [zoomDropdownOpen, setZoomDropdownOpen] = useState<boolean>(false);
  const [clear, setClear] = useState(0);
  const [urlErrorText, setURLErrorText] = useState("");

  const [username, setUsername] = useState("");
  const [url, setUrl] = useState("");

  const [verdict, setVerdict] = useState<Verdict | null>(null);
  const [isLoaded, setIsLoaded] = useState(true);

  const [error, setError] = useState("");

  const handleUserSelectionChange = (selected: Option | null) => {
    setError("");

    if (!selected) {
      setUsername("");
    } else {
      setUsername(selected.value.toString());
    }
  };

  const handleChangeURL = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");

    // https://stackoverflow.com/a/49849482
    if (validateDomain(e.target.value)) {
      setURLErrorText("Invalid url entered");
    } else {
      setURLErrorText("");
    }

    setUrl(e.target.value);
  };

  const populateVerdict = async () => {
    try {
      if (!username) {
        return;
      }

      setIsLoaded(false);
      setError("");
      const res = await getVerdict(username, url);
      setVerdict(res);
      setSubmittedURL(url);
      if (res.zoom) {
        setZoomDropdownOpen(true);
      } else {
        setZoomDropdownOpen(false);
      }
      if (res.rule) {
        setRuleDropdownOpen(true);
      } else {
        setRuleDropdownOpen(false);
      }
    } catch (err) {
      console.error(err);
      setError(verdictErrorDescText);
    } finally {
      setIsLoaded(true);
    }
  };

  const handleCheckVerdict = () => void populateVerdict();

  const handleClear = () => {
    setError("");
    setVerdict(null);
    setUsername("");
    setUrl("");
    // trigger re-render in UserSearchSelector
    setClear((prev) => prev + 1);
  };

  const handleToggleRuleDropdown = () => {
    setRuleDropdownOpen(!ruleDropdownOpen);
  };

  const handleToggleZoomDropdown = () => {
    setZoomDropdownOpen(!zoomDropdownOpen);
  };

  const handleEditRule = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (!verdict) return;
    setError("");
    onClose();
    if (verdict.rule !== undefined) {
      editRule(verdict.rule.id);
    }
  };

  const handleDeleteRule = () =>
    void (async () => {
      try {
        if (!verdict) return;
        if (!verdict.rule) return;
        setIsLoaded(false);
        await deletePolicy(verdict.rule.id);
        setVerdict(null);
        onRefresh();
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoaded(true);
      }
    })();

  const onToggleEnabled = (e: React.SyntheticEvent) =>
    void (async () => {
      try {
        e.stopPropagation();
        if (!verdict) return;
        if (!verdict.rule) return;

        setIsLoaded(false);
        const allRules = await getPolicies(false);
        const currentRule = allRules.find((r) => r.id === verdict.rule?.id);
        if (!currentRule) {
          return;
        }

        await modifyPolicy({
          ...currentRule,
          enabled: !currentRule.enabled,
        });

        setVerdict({
          ...verdict,
          rule: {
            ...verdict.rule,
            enabled: !currentRule.enabled,
          },
        });

        onRefresh();
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoaded(true);
      }
    })();

  const ruleCriteria: FilteringCriteria[] = verdict && verdict.rule ? verdict.rule.criteria[0].conditions : [];

  const getHighlightedCriteria = () => {
    if (!verdict) return [];
    return ruleCriteria.map((c, i) => {
      if (c.type === "application.http.hostname") {
        if ("source_entries" in c.conditions && Array.isArray(c.conditions.source_entries)) {
          if (c.conditions.source_entries.some((e) => submittedURL.startsWith(e))) {
            return i;
          }
        } else if (Array.isArray(c.conditions)) {
          if (c.conditions[0]) {
            const webList = websiteObjects.find((o) => Array.isArray(c.conditions) && o.id === c.conditions[0]);
            if (webList && webList.entries.some((e) => submittedURL.startsWith(e))) {
              return i;
            }
          }
        }
      }
      if (c.type === "signature") {
        if (
          Array.isArray(c.conditions) &&
          verdict.signatures?.signature &&
          (c.conditions.includes(verdict.signatures.signature) ||
            c.conditions.includes(verdict.signatures.category) ||
            c.conditions.includes(verdict.signatures.subCategory))
        ) {
          return i;
        }
      }
      return -1;
    });
  };

  const highlightedCriteria = getHighlightedCriteria();

  const getSignatureTree = (): SignatureTree[] => {
    if (!verdict) return [];
    if (!verdict.signatures) return [];
    const parentSig: Signature | undefined = signatures.find((s) => !!verdict.signatures && s.id === verdict.signatures.category);
    if (!parentSig) return [];

    const isImportant = (s: Signature) =>
      verdict?.signatures?.signature === s.id || verdict?.signatures?.category === s.id || verdict?.signatures?.subCategory === s.id;

    const doMap = (c: Signature, index: number, total: Signature[]): SignatureTree | null => {
      const importantIndex = total.findIndex((t) => isImportant(t));

      if (importantIndex === -1) {
        return null;
      }

      const spacing = c.is_category ? 1 : c.is_sub_category ? 2 : 2;

      if (importantIndex - spacing >= index) {
        return null;
      }

      if (importantIndex + spacing <= index) {
        return null;
      }

      return {
        name: c.name,
        id: c.id,
        icon: c.favicon_url,
        important: importantIndex === index,
        included: c.id === verdict?.signatures?.signature,
        action: c.id === verdict?.signatures?.signature ? verdict.verdict : -1,
        children: signatures
          .filter((s) => s.category === c.id)
          .map(doMap)
          .filter((c): c is SignatureTree => !!c),
      };
    };

    return signatures
      .filter((s) => !s.category)
      .map(doMap)
      .filter((c): c is SignatureTree => !!c);
  };

  const criteriaLabels: string[] = ruleCriteria
    .map((criteria) => convertCriteria({ criteria, keywordObjects, keywords, signatures, websiteObjects, t }))
    .filter((c): c is AllOptions => !!c)
    .map((c) => c.label);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleCheckVerdict();
  };

  const handleClose = () => {
    onClose();
    setVerdict(null);
  };

  return (
    <Modal
      isOpen={open}
      onClose={handleClose}
      headerText={headerText}
      size="md"
      contentProps={{ style: { overflow: verdict && verdict.method !== "fallback" ? "auto" : "visible" } }}
    >
      <Flex flexDirection="column">
        <Box pt="sp24">
          <Text color="text.paragraph.regular">{headerDescText}</Text>
        </Box>

        <Flex pt={!!verdict ? "sp12" : "sp24"} direction={!!verdict ? "row" : "column"}>
          <Box flexGrow={1} pr={!verdict ? undefined : "sp8"} pb={!!verdict ? undefined : "sp24"}>
            <form onSubmit={handleSubmit}>
              <TextInput
                label={t(websiteLabelText)}
                placeholder={t(websitePlaceholderText)}
                onChange={handleChangeURL}
                value={url}
                errorMessage={urlErrorText}
                width="100%"
              />
            </form>
          </Box>

          <Box flexGrow={1} pl={!verdict ? undefined : "sp8"}>
            <Text color="text.title" fontWeight={"medium"}>
              {t(userText)}
            </Text>
            <UserSearchSelector
              prefill={username}
              placeholderText={t(userPlaceholderText)}
              handleChange={handleUserSelectionChange}
              clear={clear}
              showClearIcon={true}
            />
          </Box>
          {!!verdict && (
            <Box flexGrow={1} pl="sp16" pt="sp12" mt="sp8">
              <IconButton
                icon={<Icon icon="fa-search" />}
                variant="secondary"
                onClick={handleCheckVerdict}
                aria-label="small-icon-search"
                size="default"
                disabled={!isLoaded || !username || !url}
              />
            </Box>
          )}
        </Flex>

        {!!error && (
          <Box py="sp24">
            <InlineNotification status="error" notificationTitle={errorTitle} notificationDescription={error} />
          </Box>
        )}

        {!!verdict && (
          <Box py="sp24">
            {!!verdict.rule && (
              <Box>
                <Text fontSize="md" fontWeight="medium" color="text.title" mb="sp8">
                  {t(policyText)}
                </Text>
                <Accordion
                  isOpen={ruleDropdownOpen}
                  title=""
                  titleContent={
                    <>
                      <Toggle
                        data-testid={`${verdict.rule.id}-toggle-testpolicy`}
                        isChecked={verdict.rule.enabled}
                        onChange={onToggleEnabled}
                        aria-label="Policy Enabled"
                        disabled={!isLoaded}
                      />
                      <Flex>
                        <Text
                          fontWeight="medium"
                          whiteSpace="nowrap"
                          ml="sp8"
                          mr="sp16"
                          textOverflow="ellipsis"
                          maxWidth="300px"
                          overflow="hidden"
                          flexGrow={1}
                        >
                          {verdict.rule.name}
                        </Text>
                      </Flex>
                      <Flex justifyContent="space-between" alignItems="center" width="100%">
                        <Flex justifyContent="space-evenly">
                          <StatusChip action={verdict.verdict} size="small" lineHeight="20px" marginRight="sp4" />
                          {verdict.rule.sticky && <Tag variant="subtle" tagLeftIcon="fa fa-lock" tagLabel="Locked" size="compact" />}
                        </Flex>
                        <Flex alignItems="center">
                          <Flex mr="sp8" alignItems="center">
                            <TableIconButton
                              data-testid={`${verdict.rule.id}-edit-testpolicy`}
                              icon={<Icon icon="fa-pencil" variant="solid" color="text.paragraph.light" />}
                              aria-label="Edit"
                              onClick={handleEditRule}
                              disabled={!isLoaded}
                            />
                          </Flex>
                          <DeleteButtonNew
                            data-testid={`${verdict.rule.id}-delete-testpolicy`}
                            onClick={handleDeleteRule}
                            disabled={!isLoaded}
                          />
                        </Flex>
                      </Flex>
                    </>
                  }
                  accordionPanelProps={{ padding: 0 }}
                  accordionButtonProps={{
                    onClick: handleToggleRuleDropdown,
                  }}
                >
                  <Box padding="sp16">
                    <PolicyCriteria criteriaLabels={criteriaLabels} verdict={verdict} highlightedCriteria={highlightedCriteria} />
                    <Text color="text.paragraph.light" fontWeight="light" mb="sp8" mt="sp12">
                      {t(criteriaHierarchyText)}
                    </Text>
                    <SignatureHierarchy signatureTree={getSignatureTree()} />
                  </Box>
                </Accordion>
              </Box>
            )}

            {!!verdict.zoom && (
              <Box>
                <Text fontSize="md" fontWeight="medium" color="text.title" mb="sp8">
                  {t(classroomPolicyText)}
                </Text>
                <Accordion
                  isOpen={zoomDropdownOpen}
                  title={t(classroomRuleText)}
                  titleContent={<StatusChip action={verdict.verdict} />}
                  accordionPanelProps={{ padding: 0 }}
                  accordionButtonProps={{
                    onClick: handleToggleZoomDropdown,
                  }}
                >
                  <Box padding="sp16">
                    <Box mb="sp16" bgColor="brand.100" padding="sp8" paddingLeft="sp12" paddingRight="sp12" borderRadius="4px">
                      <Text fontSize="md">Class: {verdict.zoom.sourceName || verdict.zoom.group}</Text>
                    </Box>
                    <SignatureHierarchy signatureTree={getSignatureTree()} />
                  </Box>
                </Accordion>
              </Box>
            )}

            {verdict.method === "safetynet" && <TestPolicySafetyNetMatch signatureTree={getSignatureTree()} verdict={verdict} />}
            {verdict.method === "paused" && <TestPolicyCommunityPauseInternet verdict={verdict} />}
          </Box>
        )}

        {!verdict && (
          <Flex flexDirection="row" marginTop="sp16">
            <Flex align="center">
              <Button
                variant="primary"
                onClick={handleCheckVerdict}
                aria-label="check"
                disabled={!url || !username || !!urlErrorText}
                marginEnd="10px"
                isLoading={!isLoaded}
                spinner={<Spinner size="md" />}
              >
                {t(checkButtonText)}
              </Button>
            </Flex>
            <Button marginStart={"10px"} variant="secondary" onClick={handleClear} aria-label="clear" disabled={!isLoaded}>
              {t(clearButtonText)}
            </Button>
          </Flex>
        )}

        {!!verdict && verdict.method === "fallback" && (
          <Flex width="100%" justifyContent="center" alignItems="center" backgroundColor="neutrals.10" minHeight="100px">
            <Flex flexDirection="column" justifyContent="center" alignItems="center" maxWidth="290px" padding="sp8">
              <Box bgColor="accent.green.100" padding="sp8" paddingLeft="sp12" paddingRight="sp12" mb="sp8" borderRadius="4px">
                <Text textAlign="center" fontSize="large" color="neutrals.900">
                  {t(ALLOWED_TEXT)}
                  <Icon icon="fa-check" ml="sp8" color="neutrals.900" />
                </Text>
              </Box>
              <Text textAlign="center" color="neutrals.400">
                {t(noPoliciesText)}
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Modal>
  );
};

export default TestPolicyModal;
